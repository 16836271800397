import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  title: null,
  goaluuid: null,
  status : null,
  description : null,
  currency : null,
  amount : null,
  amount_received : null,
  end_date : null,
  creation_date : null,
  image : null,
  // Add other profile-related states here
};

export const userGoalSlice = createSlice({
  name: 'userGoals',
  initialState,
  reducers: {
    setGoalData: (state, action) => {
        state.title = action.payload.title;
        state.goaluuid = action.payload.goaluuid;
        state.status = action.payload.status;
        state.description = action.payload.description;
        state.currency = action.payload.currency;
        state.amount = action.payload.amount;
        state.amount_received = action.payload.amount_received;
        state.end_date = action.payload.end_date;
        state.creation_date = action.payload.creation_date;
        state.image = action.payload.image;
    },
    // Add other reducers for different profile actions here
  },
});

// Export actions
export const { setGoalData} = userGoalSlice.actions;

// Export reducer
export default userGoalSlice.reducer;
