import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  profileImage: null,
  coverImage: null,
  displayName : null,
  userName : null,
  bio : null,
  ytURL : null,
  fbURL : null,
  instaURL : null,
  twitterURL : null,
  accountType: null,
  currency: null,
  // Add other profile-related states here
};

export const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {
    setCreatorData: (state, action) => {
        state.profileImage = action.payload.profileImage;
        state.coverImage = action.payload.coverImage;
        state.displayName = action.payload.displayName;
        state.userName = action.payload.userName;
        state.bio = action.payload.bio;
        state.ytURL = action.payload.ytURL;
        state.fbURL = action.payload.fbURL;
        state.instaURL = action.payload.instaURL;
        state.twitterURL = action.payload.twitterURL;
        state.accountType = action.payload.accountType;
        state.currency = action.payload.currency;
    },
    setCoverImage: (state, action) => {
      state.coverImage = action.payload;
    },
    // Add other reducers for different profile actions here
  },
});

// Export actions
export const { setCreatorData, setCoverImage } = userProfileSlice.actions;

// Export reducer
export default userProfileSlice.reducer;
