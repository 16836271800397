import { combineReducers, configureStore } from "@reduxjs/toolkit";

import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import signupReducer from "../State/SignUpSlice";
import authReducer , { resetStateAction } from "../State/authSlicer"; // Adjust the path based on your file structure
import userProfileReducer from "../State/userProfileSlice";
import userGoalsReducer from "../State/goalSlice";
import toastSliceReducer from "../State/toastSlice";
import resetStateMiddleware from "../State/resetStateMiddleware";
const persistConfig = {
  key: "root",
  storage,
  version: 1,
  whitelist: ["signupwizard", "auth", "userProfile", "userGoals", "toast"], // Add 'auth' here if you want it to be persisted
};

const appReducer = combineReducers({
  signupwizard: signupReducer,
  auth: authReducer,
  userProfile: userProfileReducer,
  userGoals: userGoalsReducer,
  toast: toastSliceReducer,
});

const rootReducer = (state, action) => {
  if (action.type === resetStateAction.type) {
    state = undefined;
  }
  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(resetStateMiddleware), // Add the middleware
});

export default store;
