// toastSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const toastSlice = createSlice({
  name: 'toast',
  initialState: {
    message: '',
    type: '', // e.g., 'success' or 'error'
    showToast: false,
  },
  reducers: {
    setToast: (state, action) => {
      state.message = action.payload.message;
      state.type = action.payload.type;
      state.showToast = true;
    },
    clearToast: (state) => {
      state.message = '';
      state.type = '';
      state.showToast = false;
    },
  },
});

export const { setToast, clearToast } = toastSlice.actions;
export default toastSlice.reducer;
