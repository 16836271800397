// middleware/resetStateMiddleware.js

import { createAction } from "@reduxjs/toolkit";
import { setLogOut } from "./authSlicer";

// Define a custom action to reset the entire state
export const resetStateAction = createAction("resetState");

const resetStateMiddleware = (store) => (next) => (action) => {
  if (action.type === setLogOut.type) {
    store.dispatch(resetStateAction());
  }
  return next(action);
};

export default resetStateMiddleware;
