import { Suspense, lazy } from "react";
import Loading from "./Pages/Loading";
import { Route, Routes, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
const CookiesYes = lazy(() => import("./Pages/home/CookiesYes"));
const Error = lazy(() => import("./Pages/Error"));
const Explore = lazy(() => import("./Pages/dashboard/Explore"));
const User = lazy(() => import("./Pages/dashboard/User"));
const OtherUser = lazy(() => import("./Pages/dashboard/OtherUser"));
const HomeOtherUser = lazy(() => import("./Pages/home/HomeOtherUser"));
const HomeUserCurrentGoals = lazy(() =>
  import("./Pages/home/HomeUserCurrentGoals")
);
const HomeUserCompleteGoals = lazy(() =>
  import("./Pages/home/HomeUserCompleteGoals")
);
const HomeUserGoalsDetail = lazy(() =>
  import("./Pages/home/HomeUserGoalsDetail")
);
const HomeUserCompletedGoalsDetail = lazy(() =>
  import("./Pages/home/HomeUserCompletedGoalsDetail")
);
const OtherUserCurrentGoals = lazy(() =>
  import("./Pages/dashboard/OtherUserCurrentGoals")
);
const OtherUserCompleteGoals = lazy(() =>
  import("./Pages/dashboard/OtherUserCompleteGoals")
);
const OtherUserTotalEarnings = lazy(() =>
  import("./Pages/dashboard/OtherUserTotalEarnings")
);
const CurrentGoalUser = lazy(() => import("./Pages/dashboard/CurrentGoalUser"));
const CompletedGoalUser = lazy(() =>
  import("./Pages/dashboard/CompletedGoalUser")
);
const PaymentSetting2 = lazy(() => import("./Pages/dashboard/PaymentSetting2"));
const PaymentSuccess = lazy(() => import("./Pages/dashboard/PaymentSuccess"));
const PaymentFailure = lazy(() => import("./Pages/dashboard/PaymentFailure"));
const PrivacyPolicy = lazy(() => import("./Pages/home/PrivacyPolicy"));
const TermsOfService = lazy(() => import("./Pages/home/TermsOfService"));

const AuthenticateLayout = lazy(() =>
  import("./Pages/account/Layout/AuthenticateLayout")
);

const HomeLayout = lazy(() => import("./Pages/home/Layout/HomeLayout"));
const PrivateRoutes = lazy(() => import("./Pages/PrivateRoutes"));
const LoginAuthRoute = lazy(() => import("./Pages/LoginAuthRoute"));
const CurrentGoal = lazy(() => import("./Pages/dashboard/CurrentGoal"));
const CompletedGoal = lazy(() => import("./Pages/dashboard/CompletedGoal"));
const FundGoal = lazy(() => import("./Pages/dashboard/FundGoal"));
const FundGoalUser = lazy(() => import("./Pages/dashboard/FundGoalUser"));

const Home = lazy(() => import("./Pages/home/home"));
const AccountLayout = lazy(() =>
  import("./Pages/account/Layout/AccountLayout")
);
const SignupLayout = lazy(() => import("./Pages/account/Layout/SignupLayout"));
const SignUp = lazy(() => import("./Pages/account/SignUp"));
const CreatorOrSupporterPage = lazy(() =>
  import("./Pages/account/CreatorOrSupporterPage")
);
const SelectUrl = lazy(() => import("./Pages/account/SelectUrl"));
const ChooseImg = lazy(() => import("./Pages/account/ChooseImg"));
const AboutYourself = lazy(() => import("./Pages/account/AboutYourself"));
const DashLayout = lazy(() => import("./Pages/dashboard/layout/DashLayout"));
const DashIndex = lazy(() => import("./Pages/dashboard/DashIndex"));
const EmailVerification = lazy(() =>
  import("./Pages/account/EmailVerification")
);
const Login = lazy(() => import("./Pages/account/Login"));
const OTPVerification = lazy(() => import("./Pages/account/OTPVerification"));
const SuccessEmailVerification = lazy(() =>
  import("./Pages/account/SuccessEmailVerification")
);
const ResetPassword = lazy(() => import("./Pages/account/ResetPassword"));
const ProfileSetting = lazy(() => import("./Pages/dashboard/ProfileSetting"));
const PaymentSetting = lazy(() => import("./Pages/dashboard/PaymentSetting"));
const AccountSetting = lazy(() => import("./Pages/dashboard/AccountSetting"));
const BannerDashLayout = lazy(() =>
  import("./Pages/dashboard/layout/BannerDashLayout")
);
const BannerIndex = lazy(() => import("./Pages/dashboard/BannerIndex"));
const CompletedGoals = lazy(() => import("./Pages/dashboard/CompletedGoals"));
// const Insights = lazy(() => import("./Pages/dashboard/Insights"));
const GoalsOverview = lazy(() => import("./Pages/dashboard/GoalsOverview"));
const Notification = lazy(() => import("./Pages/dashboard/Notification"));
function App() {
  
const userlogin = useSelector((state) => state.auth);  
const isLoggedIn = userlogin.uid !== null && userlogin.uid !== undefined;

  return (
    <>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/" element={<HomeLayout />}>
            <Route index exact element={<Home />} />
            <Route path="user/:username/" element={<HomeOtherUser />}>
              <Route path="current-goals" element={<HomeUserCurrentGoals />} />
              <Route
                path="current-goals/:goaluuid"
                element={<HomeUserGoalsDetail />}
              />
              <Route
                path="completed-goals/:goaluuid"
                element={<HomeUserCompletedGoalsDetail />}
              />
              <Route path="fund-goal/:goaluuid" element={<FundGoalUser />} />
              <Route
                path="completed-goals"
                element={<HomeUserCompleteGoals />}
              />
            </Route>
          </Route>
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/cookieyes-generated-policy" element={<CookiesYes />} />
          <Route element={<LoginAuthRoute />}>
            <Route path="/account" element={<AccountLayout />}>
              {/* <Route index exact element={<Login />} /> */}
              <Route index exact element={<Login />} />
              <Route path="login/:email?" element={<Login />} />
            </Route>
            <Route path="/signup" element={<SignupLayout />}>
              {/* <Route index exact element={<SignUp />} /> */}
              <Route index exact element={<SignUp />} />
              <Route
                path="select-role/:email?"
                element={<CreatorOrSupporterPage />}
              />
              <Route path="select-url" element={<SelectUrl />} />
              <Route path="select-profile-picture" element={<ChooseImg />} />
              <Route path="about-yourself" element={<AboutYourself />} />
              <Route path="OTP-verification" element={<OTPVerification />} />
            </Route>
          </Route>
          <Route path="/authentication" element={<AuthenticateLayout />}>
            <Route path="email-verification" element={<EmailVerification />} />
            {/* <Route path="OTP-verification" element={<OTPVerification />} /> */}
            <Route
              path="email-verification-success"
              element={<SuccessEmailVerification />}
            />
            <Route path="reset-password" element={<ResetPassword />} />
          </Route>
          <Route element={<PrivateRoutes />}>
            <Route element={<DashLayout />}>
              <Route path="/dashboard/" index element={<DashIndex />} />
              <Route path="explore" element={<Explore />} />
              <Route
                path="profile-setting"
                exact
                element={<ProfileSetting />}
              />
              <Route path="payment-setting" element={<PaymentSetting />} />
              <Route path="payment-setting2" element={<PaymentSetting2 />} />
              <Route
                path="payment-success/:session_id"
                element={<PaymentSuccess />}
              />
              <Route path="payment-failure" element={<PaymentFailure />} />
              <Route
                path="account-setting"
                exact
                element={<AccountSetting />}
              />
              <Route path="insights" element={<DashIndex />} />
              <Route path="goals-overview" element={<GoalsOverview />} />
              <Route path="notification" element={<Notification />} />
              {/* <Route path="user/:uid/" element={<OtherUser />}> */}
              <Route path="user/:username/" element={<OtherUser />}>
                <Route
                  path="current-goals"
                  element={<OtherUserCurrentGoals />}
                />
                <Route
                  path="current-goals/:goaluuid"
                  element={<CurrentGoalUser />}
                />
                <Route
                  path="completed-goals/:goaluuid"
                  element={<CompletedGoalUser />}
                />
                <Route path="fund-goal/:goaluuid" element={<FundGoalUser />} />
                <Route
                  path="completed-goals"
                  element={<OtherUserCompleteGoals />}
                />
                <Route
                  path="total-earnings"
                  element={<OtherUserTotalEarnings />}
                />
              </Route>
              <Route path=":username/" element={<BannerDashLayout />}>
                <Route path="user/:useruid" element={<User />} />
                <Route path="current-goals" element={<BannerIndex />} />
                {/* <Route
                  path="current-goals/:goaluuid"
                  element={<CurrentGoal />}
                /> */}
                                <Route
                  path="current-goals/:goaluuid"
                  element={<CurrentGoalUser />}
                />
                {/* <Route
                  path="completed-goals/:goaluuid"
                  element={<CompletedGoal />}
                /> */}
                                <Route
                  path="completed-goals/:goaluuid"
                  element={<CompletedGoalUser />}
                />
                <Route path="fund-goal/:goaluuid" element={<FundGoal />} />
                <Route path="completed-goals" element={<CompletedGoals />} />
              </Route>
            </Route>
          </Route>
          <Route path="*" element={<Error />} />
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
