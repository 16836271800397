import React from "react";
import "../styling/NoPageStyling.css";

function Loading() {
  return (
    <div className="min-h-screen text-[#adafb6] flex justify-center items-center flex-col ">
      <div className="boxes h-[32px] w-[32px] relative   [transform-style:preserve-3d] origin-[50%_50%] mt-[32px] [transform:rotateX(60deg)_rotateZ(45deg)_rotateY(0deg)_translateZ(0px)]  [&>div]:w-[32px] [&>div]:h-[32px] [&>div]:top-0 [&>div]:left-[0] [&>div]:absolute  [&>div_div]:absolute [&>div_div]:w-full [&>div_div]:h-full [&>div_div]:top-auto [&>div_div]:right-auto [&>div_div]:bottom-auto [&>div_div]:left-auto  ">
        <div className="box [transform-style:preserve-3d] translate-x-full translate-y-[0] animate-[box1_1s_linear_infinite]">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="box [transform-style:preserve-3d] translate-x-[0] translate-y-full animate-[box2_1s_linear_infinite]">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="box [transform-style:preserve-3d] translate-x-full translate-y-full animate-[box3_1s_linear_infinite]">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="box [transform-style:preserve-3d] translate-x-[200%] translate-y-[0] animate-[box4_1s_linear_infinite]">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <h2 className="mt-[150px] ml-[20px] text-center">Loading</h2>
    </div>
  );
}

export default Loading;
