import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  username: null,
  email: null,
  password: null,
  acceptPrivacyTerms: false,
  creatorORsupporter: null,
  customUserName: null,
  ProfilePic: null,
  DisplayName: null,
  About: null,
};

export const signupwizardSlice = createSlice({
  name: "signupwizard",
  initialState: initialState,

  reducers: {
    setWizard1: (state, action) => {
      state.username = action.payload.username;
      state.email = action.payload.email;
      state.password = action.payload.password;
      state.acceptPrivacyTerms = action.payload.acceptPrivacyTerms;
    },
    setWizard2: (state, action) => {
      state.creatorORsupporter = action.payload.creatorORsupporter;
    },
    setWizard3: (state, action) => {
      state.customUserName = action.payload.customUserName;
    },
    setWizard4: (state, action) => {
      state.ProfilePic = action.payload.ProfilePic;
    },
    setWizard5: (state, action) => {
      state.DisplayName = action.payload.DisplayName;
      state.About = action.payload.About;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setWizard1, setWizard2, setWizard3, setWizard4, setWizard5 } =
  signupwizardSlice.actions;

export default signupwizardSlice.reducer;
